<template>
    <div class="row justify-content-center">
        <div>
            <div class="card bg-secondary shadow border-0">
                <div class="card-header bg-transparent text-center">
                    <content-placeholders v-show="products.length == 0" class="m-5">
                        <content-placeholders-heading :img="true" />
                        <content-placeholders-text :lines="2" />
                    </content-placeholders>

                    <h3>Thank you, your order ID is <br/> {{ lastOrder.orderID }}</h3>
                    <h4 class="pt-2 my-2">Total Amount: {{ lastOrder.total.toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'IDR',
                                                }) }}</h4>
                    <b>Your order has been confirmed</b>
                    <h5 class="pt-2 my-2">{{ message }}</h5>
                    <h4 v-if="lastOrder.selectedPaymentMethod == 'xendit'"><a :href="invoice_url" target="_blank" class="btn btn-primary">Xendit Payment Link</a></h4>
                    <hr>
                    <div v-if="lastOrder.selectedPaymentMethod == 'Bank Transfer' || lastOrder.selectedPaymentMethod == ''">
                        <br/>We will contact you to finalize the payment process with shipping details, if you have further questions, please contact our customer service (WhatsApp) at 08880.185.0011
                        <hr>
                        <br/>Kami akan menghubungi Anda untuk menyelesaikan proses pembayaran beserta rincian pengiriman, jika ada pertanyaan lebih lanjut, silakan hubungi layanan pelanggan kami (WhatsApp) di 08880.185.0011
                    </div>
                    <!-- <div v-if="lastOrder.selectedPaymentMethod == 'Bank Transfer' || lastOrder.selectedPaymentMethod == ''">Please transfer the total amount <b>{{ lastOrder.total.toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'IDR',
                                                }) }}</b> on the invoice to:
                        <br/>PT. ASIA ASETA (BCA)
                        <br/><b>134.584.8888</b>
                        <br/>
                        <br/>To make the process faster, please send proof of transfer together with order ID to our customer service (WhatsApp) at 08880.185.0011
                        <hr>
                        Mohon transfer sesuai jumlah total <b>{{ lastOrder.total.toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'IDR',
                                                }) }}</b> ke:
                        <br/>PT. ASIA ASETA (BCA)
                        <br/><b>134.584.8888</b>
                        <br/>
                        <br/>Untuk mempercepat proses transaksi, mohon kirim bukti transfer beserta order ID ke customer service kami (WhatsApp) di
                        08880.185.0011
                    </div> -->
                    <div v-if="lastOrder.selectedPaymentMethod == 'qrplayhouse-academy'">Please pay the total amount <b>{{ lastOrder.total.toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'IDR',
                                                }) }}</b> on the invoice to the <b>QR Booth in Playhouse Academy</b>
                        <br/>
                        <br/>To make the process faster, please send proof of transfer together with order ID to our customer service (WhatsApp) at 08880.185.0011
                        <hr>
                        Mohon transfer sesuai jumlah total <b>{{ lastOrder.total.toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'IDR',
                                                }) }}</b> ke <b>Booth QR di Playhouse Academy</b>
                        <br/>
                        <br/>Untuk mempercepat proses transaksi, mohon kirim bukti transfer beserta order ID ke customer service kami (WhatsApp) di
                        08880.185.0011
                    </div>
                    <div v-if="lastOrder.selectedPaymentMethod == 'xendit'">You will be redirected to xendit payment page
                        <br/>Please proceed through the payment page and your payment should be processed directly, <br/>if there's any issue on your payment, please provide order ID to our customer service (WhatsApp) at 
                        <br/>08880.185.0011
                        <hr>
                        Anda akan dibawa ke halaman pembayaran xendit
                        <br/>Silahkan melanjutkan melalui halaman pembayaran yang ada dan jika sudah melakukan pembayaran, pesanan anda akan diproses secara otomatis,<br/>jika ada kendala dalam pembayaran, mohon kirimkan order ID ke customer service kami (WhatsApp) di
                        <br/>08880.185.0011
                    </div>
                    <div v-if="lastOrder.selectedPaymentMethod == 'VA BCA'">Please transfer the total amount on the invoice to:
                        <br/><b>10766 9999012345 (VA BCA)</b>
                        <br/>
                        <br/>Your payment should be processed directly, <br/>if there's any issue on your payment, please provide order ID to our customer service (WhatsApp) at 
                        <br/>08880.185.0011
                        <hr>
                        Mohon transfer sesuai jumlah total ke:
                        <br/><b>10766 9999012345 (VA BCA)</b>
                        <br/>
                        <br/>Jika sudah melakukan pembayaran, <br/>pesanan anda akan diproses secara otomatis, jika ada kendala dalam pembayaran, mohon kirimkan order ID ke customer service kami (WhatsApp) di
                        <br/>08880.185.0011
                    </div>
                    <!-- <div v-if="lastOrder.selectedPaymentMethod == 'VA BCA'">Please transfer the total amount on the invoice to:
                        <br/><b>10766 9999012345 (VA BCA)</b>
                        <br/>
                        <br/>Your payment should be processed directly, <br/>if there's any issue on your payment, please provide order ID to our customer service (WhatsApp) at 
                        <br/>08880.185.0011
                        <hr>
                        Mohon transfer sesuai jumlah total ke:
                        <br/><b>10766 9999012345 (VA BCA)</b>
                        <br/>
                        <br/>Jika sudah melakukan pembayaran, <br/>pesanan anda akan diproses secara otomatis, jika ada kendala dalam pembayaran, mohon kirimkan order ID ke customer service kami (WhatsApp) di
                        <br/>08880.185.0011
                    </div> -->

                </div> 

            </div>
                
            <div class="mt-3" style="text-align: center">
                <h3>Browse other amazing books:</h3>
                <div class="products px-3 py-3" v-for="product, index in products" :key="index" @click="scrollToTop()" >
                    <router-link :to="(product.url == 'kid-and-the-dragon'
                                                    || product.url == 'where-are-you' 
                                                    || product.url == 'a-very-dinosaur-birthday' 
                                                    || product.url == 'a-very-unicorn-birthday' 
                                                    || product.url == 'an-intergalactic-birthday-party' 
                                                    || product.url == 'bali' 
                                                    || product.url == 'jakarta' 
                                                    || product.url == 'good-night' 
                                                    || product.url == 'one-day-you-will-fly' 
                                                    || product.url == 'happy-birthday' 
                                                    || product.url == 'promoted-to-big-brother-sister' 
                                                    || product.url == 'ramadan-seru' 
                                                    || product.url == 'yogyakarta' 
                                                    || product.url == 'welcome-to-the-world' 
                                                    || product.url == 'where-are-you-birthday' )
                                                    ? '/'+product.url
                                                    : 
                                                        (product.type == 'Personalized Book')
                                                        ? '/custombook/'+product.url
                                                        : '/product/'+product.url
                                                    ">
                    <!-- <router-link :to="product.url"> -->
                        <div class="book"
                            :style="{ backgroundImage: 'url(' + product.img + ')' }">
                        </div>
                        <div class="pt-4 text-dark name"><b>{{ product.name }}</b></div>
                        <div class="pb-3 text-dark price">{{ parseInt(product.price).toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'IDR',
                                    }) }}</div>
                                    
                    </router-link>
                </div>
            </div>   
        </div>
    </div>
</template>
<script>
import firebaseApp from "../firebase/firebaseInit";
const db = firebaseApp.firestore();

export default {
    name: 'bookabook',
    data() {
        return {
            message: null,
            orderUID: null,
            invoice_url: null,
            model: {
            }
        }
    },
    created() {
        if (this.lastOrder.selectedPaymentMethod == 'xendit')
            this.checkLastOrder(this.lastOrder);
        
    },
    mounted() {
        console.log(this.lastOrder);
    },
    computed: {
        lastOrder() {
            return this.$store.state.lastOrder;
        },
        products() {
            return this.$store.state.products.filter(obj => {
                    return obj.status === 'active';
                    });
        }
    },
    methods: {
        async checkLastOrder(order){
            this.message = 'checking your order status..';
            const snapshot = await db.collection('orders').where('orderID','==',order.orderID).get()
            if (snapshot.empty) {
                console.log('No matching orders.');
                return;
            }  

            snapshot.forEach(doc => {
                console.log(doc.id);
                console.log(doc.data());
                this.orderUID = doc.id;
                if(doc.data().paymentStatus != 'Paid' && doc.data().paymentStatus != 'paid'){
                    this.message = 'checking your payment status..';
                    this.checkXenditInvoice(doc.data());
                } else {
                    this.message = 'Your order has been successfully paid';
                }
            });


        },
        checkXenditInvoice(order){
            console.log('checkXenditInvoice');
            if(order.xendit)
            {
                if(order.xendit.invoice_url){
                    const invoice_url = order.xendit.invoice_url
                    this.message = "Payment link found, if you haven't been redirected, please follow this link below:";
                    this.invoice_url = invoice_url;
                    window.open(invoice_url);
                } else {
                    alert('Error, xendit found but invoice url not found');
                }
            } else {
                this.createXenditInvoice(order);
            }
            
        },
        createXenditInvoice(order){
            this.message = 'creating your xendit Invoice..';
                
            console.log(this.message);
            console.log(order);
            const apiURL = 'https://us-central1-book-abook.cloudfunctions.net/xendit_invoice_create';
            // const apiURL = 'http://127.0.0.1:5001/book-abook/us-central1/xendit_invoice_create';
            let data = JSON.stringify({
                "external_id": order.orderID,
                "amount": order.total,
                "payer_email": order.email,
                "description": "Bookabook order number: "+order.orderID
            });

            let config = {
                method: 'post',
                url: apiURL,
                headers: { 
                    'Content-Type': 'application/json', 
                },
                data : data
            };

            this.$http(config)
            .then((response) => {
                console.log(response.data)
                
                db.collection('orders').doc(this.orderUID).update({xendit: response.data});
                if(response.data.invoice_url){
                    this.message = "Payment link has been created, if you haven't been redirected, please follow this link below:";
                    this.invoice_url = response.data.invoice_url;
                    window.open(response.data.invoice_url);
                    // console.log(response.data.invoice_url);
                }
            }).catch((error)=>{
                alert(error);
            })
        },
        scrollToTop() {
            document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
        }
    }
}
</script>